/* global require */
// This file is a set of helpers to load all the angularjs templates
//  into the templateCache so that they're available in the browser

// helpful article from StackOverflow:
// https://stackoverflow.com/questions/33300289/how-to-use-webpack-with-angular-templatecache#answer-43842676
//
const debug = false;

// app: angularjs application
const loadTemplates = (app) => {
  app.run([
    '$templateCache',
    function ($templateCache) {
      // this returns a list of filenames ending in .html
      // returns: ["./home/home.html", "./other_file/etc.html"]
      // if (debug) console.log('FILENAMES: ', filenames);
      // NOTE: making sure to only get files that start with `./`. Due to resolve config in our webpack
      // require.context will return duplicate paths (eg. ./file.jsx, ./file)
      // Since we are manually requiring each file, we only want to have a single copy
      const filenames = require.context('./templates', true, /^\.\/.+\.html$/).keys();

      // Reads in the file contents as a string:
      // returns: '<div>hello world</div>'
      // if (debug) console.log('Content: ', homeTemplate );
      // const homeTemplate = require('./templates/home/home.html');

      // Note: We'll need to transform the filename here too
      const templates = filenames.forEach((filename) => {
        // transforms: './home/home.html' to 'home/home.html'
        const filenameFormatted = filename.replace(/^\.\//, '');

        const template = {
          filenameFormatted,
          html: require(`./templates/${filenameFormatted}`),
        };
        $templateCache.put(template.filenameFormatted, template.html);
        // if (debug) console.log(template);
      });
      return true;
    },
  ]);
};

const loadJavascripts = () => {
  // NOTE: making sure to only get files that start with `./`. Due to resolve config in our webpack
  // require.context will return duplicate paths (eg. ./file.jsx, ./file)
  // Since we are manually requiring each file, we only want to have a single copy
  const filenames = require.context('./templates', true, /^\.\/.+\.js$/).keys();

  const templates = filenames.forEach((filename) => {
    const filenameFormatted = filename.replace(/^\.\//, '');
    const controller = require(`./templates/${filenameFormatted}`);
    // run the file, load the controller
    // controller.default();
  });
};

export default { loadTemplates, loadJavascripts };
