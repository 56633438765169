import { angularStates } from './statesUrls';

// All the states that should only be accessible by instructors
//  should be in this file.
app.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider

      // only permit instructors to see these routes
      .state('app.instructor', {
        template: '<ui-view/>',
        abstract: true, // this state will not have a url
        resolve: {
          isInstructor: [
            'currentUser',
            '$state',
            '$q',
            function (currentUser, $state, $q) {
              if (!currentUser.instructor) {
                console.log('Only instructors may access this route. You do not have permission.');
                $state.go('app.home');
                return $q.reject();
              }
              return true;
            },
          ],
        },
      })

      .state('app.instructor.class', {
        url: angularStates['app.instructor.class'],
        templateUrl: 'class/instructor_view.html',
        controller: 'ClassController',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          // Note: We add currentUser as an argument to our controller so that it needs to resolve before we try
          //  to get new templates.
          templates: [
            'OrganizationService',
            'currentUser',
            function (OrganizationService) {
              return OrganizationService.getTemplates();
            },
          ],
        },
      })
      // TODO do controller injections on all pages, clean up 'resolve' in states
      .state('app.instructor.section', {
        abstract: true,
        url: '/class/:code',
        templateUrl: 'class/_template.html',
        controller: 'ClasslistController',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          testSettings: [
            'UserSettings',
            '$stateParams',
            function (UserSettings, $stateParams) {
              return UserSettings.get('class/' + $stateParams.code + '/test');
            },
          ],
          contestSettings: [
            'UserSettings',
            '$stateParams',
            function (UserSettings, $stateParams) {
              return UserSettings.get('class/' + $stateParams.code + '/contest');
            },
          ],
          testLinksSettings: [
            'UserSettings',
            '$stateParams',
            function (UserSettings, $stateParams) {
              return UserSettings.get('class/' + $stateParams.code + '/testLinks');
            },
          ],
          viewSettings: [
            'UserSettings',
            '$stateParams',
            function (UserSettings, $stateParams) {
              // Note: If the path below is changed, it must also be changed in the controller
              return UserSettings.get('class/' + $stateParams.code + '/view');
            },
          ],
          scrollToTop: [
            function () {
              window.scrollTo(0, 0); // scrolls to top of page
              return true;
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Our two core states are below. The share the same controller defined on the abstract template
      // above.
      .state('app.instructor.section.keyboarding', {
        url: '/keyboarding',
        templateUrl: 'class/_classlist.html',
      })
      .state('app.instructor.section.document_production', {
        url: '/document_production',
        templateUrl: 'class/_classlist_document_production.html',
      })
      .state('app.instructor.section.test_links', {
        url: '/test_links',
        templateUrl: 'class/components/test_links/_test_links.html',
      })
      .state('app.instructor.section.studentView', {
        url: '/student/:student_id',
        templateUrl: 'class/student_view/_student_view_as_instructor.html',
        controller: 'StudentViewAsInstructorController',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          scrollToTop: [
            function () {
              window.scrollTo(0, 0); // scrolls to top of page
              return true;
            },
          ],
        },
      })
      .state('app.instructor.section.assignmentView', {
        url: '/document_assignment/:id',
        templateUrl: 'class/assignment_view/assignment_view_as_instructor.html',
        controller: 'AssignmentViewAsInstructorController',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          scrollToTop: [
            function () {
              window.scrollTo(0, 0); // scrolls to top of page
              return true;
            },
          ],
        },
      })

      .state('app.instructor.accommodation', {
        url: angularStates['app.instructor.accommodation'],
        // params: {notification_id: null},  // must be present for notification_id to show up
        templateUrl: 'instructor/_accommodation.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  if (!user.instructor) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.instructor.transcription_copy', {
        url: angularStates['app.instructor.transcription_copy'],
        // params: {notification_id: null},  // must be present for notification_id to show up
        templateUrl: 'instructor/_transcription_copy.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  if (!user.instructor) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.instructor.tutorials', {
        url: angularStates['app.instructor.tutorials'],
        // params: {notification_id: null},  // must be present for notification_id to show up
        templateUrl: 'instructor/_tutorials.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  if (!user.instructor) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.instructor.learn_document_production', {
        url: angularStates['app.instructor.learn_document_production'],
        controller: 'LearnDocumentProductionController',
        templateUrl: 'learn_document_production/learn_document_production.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  if (!user.instructor) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Parent abstract state for the course templates page view for instructors
      .state('app.instructor.course_templates', {
        url: angularStates['app.instructor.course_templates'],
        controller: 'CourseTemplateController',
        template: '<ui-view/>',
        abstract: true,
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          course_templates: [
            '$http',
            function ($http) {
              return $http.get('api/templates.json').then(function (response) {
                return response.data;
              });
            },
          ],
          organization: [
            '$http',
            'Auth2',
            function ($http, Auth2) {
              return Auth2.currentUser().then(function (user) {
                return $http.get('organization/' + user.organization_id).then(function (response) {
                  return response.data;
                });
              });
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // List all the course templates
      .state('app.instructor.course_templates.index', {
        url: angularStates['app.instructor.course_templates.index'],
        templateUrl: 'course_templates/_course_templates.html',
      })

      // Show a specific course template
      .state('app.instructor.course_templates.show', {
        url: '/:id',
        controller: [
          '$scope',
          'course_template',
          function ($scope, course_template) {
            $scope.course_template = course_template;
          },
        ],
        resolve: {
          course_template: [
            '$q',
            '$stateParams',
            'course_templates',
            function ($q, $stateParams, course_templates) {
              const course_template = course_templates.find((courseTemplate) => {
                return courseTemplate.id === parseInt($stateParams.id, 10);
              });
              if (course_template) {
                return course_template;
              } else {
                return $q.reject();
              }
            },
          ],
        },
        templateUrl: 'course_templates/components/_course_template.html',
      });
  },
]);
