// angularStates stores a map of the state names used in our nav to the urls they map to.
// this is so that our React navigation component can direct users appropriately while
// maintaining a single source of truth for our urls.
import { angularStates } from './statesUrls';

// All the states that should only be accessible by administrators
app.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider

      // only permit admins to see these routes
      .state('app.admin', {
        template: '<ui-view/>',
        abstract: true, // this state will not have a url
        resolve: {
          isAdmin: [
            'currentUser',
            '$state',
            '$q',
            function (currentUser, $state, $q) {
              console.log('Admin?');

              if (!currentUser.admin) {
                console.log('Only administrators may access this route. You do not have permission.');
                $state.go('app.home');
                return $q.reject();
              }
              return true;
            },
          ],
        },
      })

      .state('app.admin.load_test', {
        // designeed to test a large json post
        url: angularStates['app.admin.load_test'],
        // params: {notification_id: null},  // must be present for notification_id to show up
        templateUrl: 'timed_writing/load_test/_load_test_timed_writing.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Used to test whether the browser is registering the correct keypresses
      .state('app.admin.keyboard_test', {
        url: angularStates['app.admin.keyboard_test'],
        params: { text: null }, // must be present for notification_id to show up
        templateUrl: 'keyboard/_keyboard_test_env.html',
        controller: 'KeyboardTestController',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  if (!user.admin) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          accountSettings: [
            'UserSettings',
            'DefaultSettings',
            (UserSettings, DefaultSettings) => {
              return UserSettings.get('accountSettings').then((res) => res.orDefault(DefaultSettings.accountSettings()));
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Adding Admin page for Greg and I to track signups
      .state('app.admin.dashboard', {
        url: angularStates['app.admin.dashboard'],
        // params: {notification_id: null},  // must be present for notification_id to show up
        templateUrl: 'admin/_dashboard.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  // only gregjebonnell@gmail.com and mattmcinnis (typistapp@gmail.com) and pammurray10@gmail.com
                  if (!(user.id === 288 || user.id === 141 || user.id === 7)) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Commission page for Greg
      .state('app.admin.commission', {
        url: angularStates['app.admin.commission'],
        // params: {notification_id: null},  // must be present for notification_id to show up
        templateUrl: 'admin/_commission_dashboard.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  // only gregjebonnell@gmail.com and mattmcinnis (typistapp@gmail.com) and pammurray10@gmail.com
                  if (!(user.id === 288 || user.id === 141 || user.id === 7)) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Allows admin users to edit, update and create practice text
      .state('app.admin.practice_text', {
        url: angularStates['app.admin.practice_text'],
        // params: {notification_id: null},  // must be present for notification_id to show up
        controller: 'PracticeTextController',
        templateUrl: 'practice_text/_index.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  // only gregjebonnell@gmail.com and mattmcinnis (typistapp@gmail.com) can access
                  if (!user.admin) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Allows admin users to edit, update and create practice text
      .state('app.admin.practice_text#show', {
        url: '/practice_text/:id',
        // params: {notification_id: null},  // must be present for notification_id to show up
        controller: 'PracticeTextController',
        templateUrl: 'practice_text/_index.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                  // only gregjebonnell@gmail.com and mattmcinnis (typistapp@gmail.com) can access
                  if (!(user.id === 7 || user.id === 141 || user.id === 1283 || user.id === 1284)) {
                    $state.go('app.home'); // only instructors may access this page
                  }
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      });
  },
]);
