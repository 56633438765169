/* global require */

import angular from 'angular';
import '@uirouter/angularjs';
import 'angular-spinner';
import '../vendor/angular-chart.js';
import '../vendor/angular-devise.js';
import 'angular-animate'; // ngAnimate
import 'angular-sanitize'; // ngSanitize
// Note: We use ProvidePlugin in webpack config to install JQuery and Popper.js -- dependencies of bootstrap.
import 'bootstrap'; // loads javascript needed for Bootstrap 4
import templateLoader from './templateLoader';
import '../components/FirebaseDb/FirebaseDb.js';

// images that we use in our angular app, where the value is the url path to the image after webpack emitted it
import imagesMapping from '~Root/app/javascript/shared/imagesMapping.js';

// statesUrls stores a map of the state names used in our nav to the urls they map to.
// this is so that our React navigation component can direct users appropriately while
// maintaining a single source of truth for our urls.
import { angularStates } from './statesUrls';

// Import the instantiated Bugsnag notifier service here
// so that it starts as soon as the app is loaded
// eslint-disable-next-line no-unused-vars
import BugsnagNotify from '../../javascript/components/BugsnagNotify/BugsnagNotify.js';

import 'blueimp-file-upload/js/jquery.fileupload.js';
import 'blueimp-file-upload/js/vendor/jquery.ui.widget.js';

// SETUP ---------
// create our angular application and set it as a global variable
// expose angularjs app as a global variable to window
// var app = angular.module("ptt", ["chart.js", "templates", "ui.router", "angularSpinner", "Devise", "angularPayments", "ngAnimate", "ngSanitize"]);
// Notes on dependencies:
// 1. Removed 'templates' since we are not using this gem anymore, packaging everything with webpack
// 2. Removed angularPayments since I don't believe we're using this package either
let app = angular.module('ptt', ['chart.js', 'ui.router', 'angularSpinner', 'Devise', 'ngSanitize']);
window.app = app;

// load keyboarding states
require('./keyboarding_states.js');
// load instructor states
require('./instructor_states.js');
// load admin states
require('./admin_states.js');
// load documents states
// NOTE: These states are located in documents_production folder, and are
//  automatically loaded with our templateLoader below.

// load all the templates in the templateCache
templateLoader.loadTemplates(app);
// load all the javascripts within the templates directory
templateLoader.loadJavascripts();

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'AuthInterceptProvider',
  function ($stateProvider, $urlRouterProvider, AuthInterceptProvider) {
    // Intercept 401 Unauthorized everywhere
    AuthInterceptProvider.interceptAuth(true);

    $urlRouterProvider.otherwise(function ($injector) {
      var $state = $injector.get('$state');

      $state.go('authentication.sign_in');
    });

    $stateProvider
      // HOME STATES AND NESTED VIEWS ========================================
      //  Note: Login/Auth views are in auth/auth_states.js

      // Acts as the parent state for all states below.
      //  child states use the '.' operator. Great article here: https://github.com/angular-ui/ui-router/wiki/Nested-States-%26-Nested-Views
      .state('app', {
        template: '<ui-view/>',
        abstract: true, // this state will not have a url
        resolve: {
          currentUser: [
            'Auth2',
            '$state',
            '$q',
            function (Auth2, $state, $q) {
              console.log('Authenticated?');
              return Auth2.currentUser().catch((e) => {
                console.log('You must be logged in to access this route. Redirecting to login page.');
                $state.go('authentication.sign_in');
                // we return a rejected promise to prevent child states from resolving
                return $q.reject(e);
              });
            },
          ],
        },
      })

      .state('app.home', {
        url: angularStates['app.home'],
        templateUrl: 'home/home.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            function (Auth2, $state) {
              return Auth2.currentUser().then(
                function (user) {
                  if (user.instructor) {
                    $state.go('app.instructor.class'); // bounce instructors to the home screen
                  }
                  return;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          scrollToTop: [
            function () {
              window.scrollTo(0, 0); // scrolls to top of page
              return true;
            },
          ],
        },
      })

      // State for purchasing subscriptions (eg. adding keyboarding and/or document production)
      .state('app.subscriptions', {
        url: '/subscriptions',
        abstract: true,
        template: '<ui-view></ui-view>',
        params: {
          flashMessage: null,
        },
      })

      // Product page for document production
      //  users are redirected here when attempting to access documents but they don't
      //  have an active documents subscription
      .state('app.subscriptions.document_production', {
        url: angularStates['app.subscriptions.document_production'],
        templateUrl: 'subscriptions/document_production_subscription.html',
        controller: 'SubscriptionController',
        resolve: {
          // gets the pricing information for the particular school
          product: [
            '$stateParams',
            '$http',
            function ($stateParams, $http) {
              const productId = '3';
              return $http.get('organization/product/' + productId + '.json').then(function (product) {
                return product.data;
              });
            },
          ],
        },
      })

      // Page where users can see products they have subscriptions to and can change email address
      .state('app.account', {
        url: angularStates['app.account'],
        templateUrl: 'user_settings/account.html',
        controller: 'AccountController',
        resolve: {
          accountSettings: [
            'UserSettings',
            'DefaultSettings',
            (UserSettings, DefaultSettings) => {
              return UserSettings.get('accountSettings').then((res) => res.orDefault(DefaultSettings.accountSettings()));
            },
          ],
        },
      })

      .state('app.settings/my_classes', {
        url: angularStates['app.settings/my_classes'],
        params: { notification_id: null }, // must be present for notification_id to show up
        templateUrl: 'user_settings/_my_classes.html',
        controller: 'ClassController',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          templates: [
            function () {
              return []; // templates are not needed here, since classes cannot be created on this screen
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.contacts_directory_about', {
        url: '/about_contact_directory',
        controller: 'ContactsDirectoryAboutController',
        templateUrl: 'document_production/instruction/contacts_directory/contacts_directory_about.html',
        resolve: {
          scrollToTop: [
            function () {
              window.scrollTo(0, 0); // scrolls to top of page
              return true;
            },
          ],
        },
      });
  },
]);

// KeyboardService - Used to broadcast keydown listeners in app.run below
app.service('keyboardService', [
  function () {
    this.watch = function (event_type, callback) {
      document.addEventListener(event_type, callback, false);
    };

    this.on = function (event_type, callback) {
      $(window).on(event_type, callback);
    };
  },
]);

// Allows $rootScope to broadcast the keydown/keyup events
//  To use in controller, use: $scope.$on("keydown", function(a, e) {})
app.run([
  '$rootScope',
  '$state',
  'keyboardService',
  'keyboardUtilities',
  'UserSettings',
  function ($rootScope, $state, keyboardService, keyboardUtilities, UserSettings) {
    // adds event listeners and protects against multiple logins
    // we have this line here so that prettier doesn't complain and so it's explicit that
    // we're addinging these listeners.
    UserSettings;

    // bind state to rootScope for access in templates
    //      More info: https://github.com/angular-ui/ui-router/wiki/Quick-Reference#statecurrent
    $rootScope.$state = $state;

    // do not broadcast these keypresses
    const doNotBroadcastKeysLowcase = ['numlock'];

    // broadcast event_type = "keydown"
    keyboardService.watch('keydown', function (e) {
      e.timeStampAdjusted = Date.now();
      // Although browsers a event.timeStamp property, which should be the same as performance.now,
      // some implementation of Safari have enormous and incorrect event.timeStamp properties.
      // However, performance.now() still seems to work correctly so we add it here.
      // Also: IE11 seems to use event.timeStamp as Date.now()
      e.performanceNow = performance.now();
      e.performanceNowInt = Math.floor(e.performanceNow);
      $rootScope.$apply(function () {
        var broadcast = e;
        broadcast.key_pressed = keyboardUtilities.getKeyPressed(e, true);
        // we wrap this in an if statement because of an error in Chrome
        // where the browser auto-populates a login form and calls this function
        // with little data in the event
        if (broadcast.key_pressed) {
          broadcast.key_pressed_lowcase = broadcast.key_pressed.toLowerCase();
          // ignore numlock key presses
          if (doNotBroadcastKeysLowcase.indexOf(broadcast.key_pressed_lowcase) > -1) return;
          $rootScope.$broadcast('keydown', broadcast);
        }
        // console.log(broadcast);
      });
    });

    // broadcast event_type = "keyup"
    keyboardService.watch('keyup', function (e) {
      $rootScope.$apply(function () {
        var broadcast = e;
        broadcast.key_released = keyboardUtilities.getKeyPressed(e, true);
        // we wrap this in an if statement because of an error in Chrome
        // where the browser auto-populates a login form and calls this function
        // with little data in the event
        if (broadcast.key_released) {
          broadcast.key_released_lowcase = broadcast.key_released.toLowerCase();
          // ignore numlock key presses
          if (doNotBroadcastKeysLowcase.indexOf(broadcast.key_released_lowcase) > -1) return;
          $rootScope.$broadcast('keyup', broadcast);
        }
      });
    });

    // TODO: Move these to a keyboard controller. Add a method to destroy the event handles once called
    keyboardService.on('blur', function (e) {
      $rootScope.$apply(function () {
        // e.type = "blur"
        var broadcast = e;
        $rootScope.$broadcast('blur', broadcast);
      });
    });

    keyboardService.on('focus', function (e) {
      $rootScope.$apply(function () {
        // e.type = "focs"
        var broadcast = e;
        $rootScope.$broadcast('focus', broadcast);
      });
    });
  },
]);

app.config([
  '$sceDelegateProvider',
  ($sceDelegateProvider) => {
    $sceDelegateProvider.resourceUrlWhitelist([
      // Allow same origin resource loads.
      'self',
      // Allow loading from our s3 bucket where we currently have mp4s.
      'https://production-assets-typist.s3.amazonaws.com/**',
    ]);
  },
]);

// This code fixes an issue where modals cause the user to be unable to scroll, and thus Typist appears unresponsive.
// It's caused by classes that Bootstrap 5s modal.js adds to body, such as overflow:hidden, that are
// not removed while switching states (since the cleanup function is not activated).
// To fix, we call the .hide() method: https://github.com/twbs/bootstrap/blob/v5.1.3/js/src/modal.js
app.run([
  '$rootScope',
  function ($rootScope) {
    // Even though $stateChangeSuccess is sufficient, running on start makes sure that any clean-up happens without interfering
    // with creation of a new modal once the state transition is complete.
    $rootScope.$on('$stateChangeStart', function () {
      const modals = document.querySelectorAll('.modal');
      if (modals.length < 1) return;
      modals.forEach((modal) => {
        const modalInstance = bootstrap.Modal.getInstance(modal);
        // This will run the scrollbar utility .reset() as well, which will remove `overflow: hidden` and `paddingRight` styles
        // that are normally added when a modal is open. We can call this on any instance of a modal.
        if (modalInstance) modalInstance.hide();
      });
    });
  },
]);

// Intercept any unauthorized requests and force the app to log out
// if user is not authenticated
app.run([
  '$rootScope',
  '$state',
  'Auth2',
  function ($rootScope, $state, Auth2) {
    $rootScope.$on('devise:unauthorized', function (_event, xhr, deferred) {
      deferred.reject(xhr); // make sure the original request still rejects

      // Home page gets a number of 401 requests as it tries to get currentUser,
      // so to prevent endless loops, only logout if 401 request detected on any of the
      // app routes (we don't want "logout" a user trying to register on the Authenticate routes)
      if ($state.current.name.indexOf('app') > -1) {
        Auth2.logout();
      }
    });
  },
]);

// Accessibility fix: Return keyboard focus to the body when state changes to mimic how static websites work
app.run([
  '$rootScope',
  '$timeout',
  function ($rootScope, $timeout) {
    let isFirstPageLoad = true;

    $rootScope.$on('$stateChangeSuccess', function () {
      // don't read out 'Navigated to ___' when we load the page
      if (isFirstPageLoad) {
        isFirstPageLoad = false;
        return;
      }

      // a tabindex="-1" is added to the body element and on state change
      // we focus to the body to mimic how a vanilla website would function
      $timeout(() => document.body.focus(), 0);

      // Use $timeout to ensure the new page content is fully rendered
      $timeout(() => {
        // Find the first H1 element in the new HTML content
        const mainHeading = document.querySelector('h1');
        const liveRegion = document.querySelector('#sr-navigation-announcement');

        // Ensure the live region and the main heading exist
        if (liveRegion && mainHeading) {
          // Retrieve the custom aria-live content and politeness level, if present
          const customContent = mainHeading.getAttribute('data-aria-live-content');
          const politenessLevel = mainHeading.getAttribute('data-aria-live') || 'polite';

          // Update the aria-live region's politeness level
          liveRegion.setAttribute('aria-live', politenessLevel);

          // Update the aria-live region with the custom content or the heading's text content
          const navigationAnnouncement = customContent
            ? `Navigated to ${customContent}`
            : `Navigated to ${mainHeading.textContent.trim()}`;
          liveRegion.textContent = navigationAnnouncement;

          const style = 'font-weight: bold;';
          console.log(`🧪 %c"${navigationAnnouncement}"`, style, `> VoiceOver Page Change Test`);
        } else {
          const errorMessage = 'Error: VoiceOver Page Change Test.';
          console.warn(
            `🧪 %c${errorMessage}`,
            'font-weight: bold;',
            ' Could not set aria-live content to announce the state change. Check that new page has an H1 element'
          );
        }
      }, 300);
    });
  },
]);

// IMPORTANT - a fix for bootstrap tooltips not being removed between angular state changes
// To reproduce the error with this section disabled: when you hover over element with tooltip,
// click on the element while tooltip is active, the tooltip remains on the next page and is no
// longer responsive
app.run([
  '$rootScope',
  function ($rootScope) {
    $rootScope.$on('$stateChangeSuccess', function () {
      // Code snippet from docs https://getbootstrap.com/docs/5.1/components/tooltips/#example-enable-tooltips-everywhere
      [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(function (tooltipTriggerEl) {
        bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl).dispose();
      });
    });
  },
]);

// Attaching imagesMapping object to root scope so that all views have access to it
// The object contains mappings to url image paths after they are emitted by webpack
app.run([
  '$rootScope',
  function ($rootScope) {
    $rootScope.imagesMapping = imagesMapping;
  },
]);

export default {};
