/* global process */

// Initializes firebase real-time database
import { initializeApp } from 'firebase/app';

let firebaseConfig;
if (process.env.NODE_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyCdZFsE7p17a5wpKTLdtzgXhjdd4aO9Gj0',
    authDomain: 'scorching-inferno-9920.firebaseapp.com',
    databaseURL: 'https://scorching-inferno-9920.firebaseio.com',
    storageBucket: 'scorching-inferno-9920.appspot.com',
  };
} else if (process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyBtZqwvjDf58VM9t55qL7wKAjrn80_kFTk',
    authDomain: 'typist-development.firebaseapp.com',
    databaseURL: 'https://typist-development-default-rtdb.firebaseio.com/',
    storageBucket: 'typist-development.appspot.com',
  };
}

const app = initializeApp(firebaseConfig);
// const firebaseDb = getDatabase(app); // <-- Pass in the initialized app
export default app;
