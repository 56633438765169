import { getDatabase, ref, get } from 'firebase/database';

// angularStates stores a map of the state names used in our nav to the urls they map to.
// this is so that our React navigation component can direct users appropriately while
// maintaining a single source of truth for our urls.
import { angularStates } from './statesUrls';
const db = getDatabase();

// All the states that should only be accessible by students who have purchased
//  access to the keyboarding software should be in this file.
app.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider

      // only permit users who have purchased access to the keyboarding product to see
      //  routes prefixed with app.keyboarding
      .state('app.keyboarding', {
        template: '<ui-view/>',
        abstract: true, // this state will not have a url
        resolve: {
          hasPurchasedKeyboarding: [
            'currentUser',
            function (currentUser) {
              console.log('Access to Keyboarding?');

              // UNCOMMENT LINES BELOW TO REENABLE SUBSCRIPTION WORKFLOW
              // SEE THE EXAMPLE WITHIN DOCUMENT PRODUCTION STATES
              // // Temporary debugging log for testing users with hard coded subscriptions
              // if (currentUser.username === 'student2') {
              //   console.log('This user only has keyboarding, but not document production')
              // } else if (currentUser.username === 'student3') {
              //   console.log('This user has access to both document production and keyboarding')
              // } else {
              //   console.log('This user doesnt have access to document production')
              //   // redirect and send a flash message if user does not have subscription
              //   // $state.go('app.subscriptions.keyboarding', { flashMessage: { message: 'You have not purchased keyboarding.', error: true } });
              //   // return $q.reject(e);
              // }

              // if (false) {
              //   console.log(
              //     'Only users who have purchased access to keyboarding may access this route. You do not have permission.'
              //   );
              //   $state.go('app.home');
              //   return $q.reject(e);
              // }
              return currentUser;
            },
          ],
        },
      })

      .state('app.keyboarding.profile', {
        url: angularStates['app.keyboarding.profile'],
        templateUrl: 'pages/profile.html',
        resolve: {},
      })

      .state('app.keyboarding.games', {
        url: angularStates['app.keyboarding.games'],
        controller: 'GamesController',
        templateUrl: 'games/_games_home.html',
      })

      .state('app.keyboarding.competition', {
        url: angularStates['app.keyboarding.competition'],
        controller: 'CompetitionController',
        params: { contest_id: null, notification_id: null, notification: null }, // must be present for notification_id to show up
        templateUrl: 'championship/_competition.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          contest: [
            '$stateParams',
            function ($stateParams) {
              return get(ref(db, `games/contests/${$stateParams['contest_id']}`)).then(function (snapshot) {
                return snapshot.val();
              });
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.keyboarding.competitionResults', {
        url: '/games/championship/results/:id',
        templateUrl: 'championship/_results.html',
        controller: 'ResultsController',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          contest: [
            '$state',
            '$stateParams',
            function ($state, $stateParams) {
              return get(ref(db, `games/contests/${$stateParams['id']}`)).then(function (snapshot) {
                return snapshot.val();
              });
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.keyboarding.practice_timed_writing', {
        url: angularStates['app.keyboarding.practice_timed_writing'],
        // params: {notification_id: null},  // must be present for notification_id to show up
        controller: 'PracticeTimedWritingController',
        templateUrl: 'timed_writing/practice/_practice_timed_writing.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          userSettings: [
            'UserSettings',
            function (UserSettings) {
              return UserSettings.get('practice_timed_writing');
            },
          ],
          addHomeRowTechSettingsToCache: [
            'UserSettings',
            'CacheService',
            function (UserSettings, CacheService) {
              // Get homeRowTech settings from firebase, put them in the cache so we can access them in SkipHomeRowTechModal
              //    FYI UserSettings and CacheService get updated from HomeRowTechModalFactory
              return UserSettings.get('homeRowTech').then(function (setting) {
                CacheService.set(setting.orDefault({ lastCompletedAt: null }), 'homeRowTech'); // set homeRowTech to the cache
              });
            },
          ],
          accountSettings: [
            'UserSettings',
            'DefaultSettings',
            (UserSettings, DefaultSettings) => {
              return UserSettings.get('accountSettings').then((res) => res.orDefault(DefaultSettings.accountSettings()));
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.keyboarding.supervised_timed_writing', {
        url: angularStates['app.keyboarding.supervised_timed_writing'],
        params: { notification_id: null }, // must be present for notification_id to show up
        controller: 'TimedWritingController',
        templateUrl: 'timed_writing/supervised/_timed_writing.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          addHomeRowTechSettingsToCache: [
            'UserSettings',
            'CacheService',
            function (UserSettings, CacheService) {
              // Get homeRowTech settings from firebase, put them in the cache so we can access them in SkipHomeRowTechModal
              //    FYI UserSettings and CacheService get updated from HomeRowTechModalFactory
              return UserSettings.get('homeRowTech').then(function (setting) {
                CacheService.set(setting.orDefault({ lastCompletedAt: null }), 'homeRowTech'); // set homeRowTech to the cache
              });
            },
          ],
          accountSettings: [
            'UserSettings',
            'DefaultSettings',
            (UserSettings, DefaultSettings) => {
              return UserSettings.get('accountSettings').then((res) => res.orDefault(DefaultSettings.accountSettings()));
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.keyboarding.transcription', {
        url: angularStates['app.keyboarding.transcription'],
        params: { skipModals: false }, // must be present for notification_id to show up
        controller: 'TranscriptionController',
        templateUrl: 'transcription/_transcription.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          transcriptionSettings: [
            'UserSettings',
            function (UserSettings) {
              return UserSettings.get('transcription');
            },
          ],
          scrollToTop: [
            function () {
              window.scrollTo(0, 0); // scrolls to top of page
              return true;
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      // Levels, Challenges and Lessons
      .state('app.keyboarding.level', {
        url: angularStates['app.keyboarding.level'],
        params: { level: null }, // must be present for notification_id to show up
        templateUrl: 'level/_level.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.keyboarding.challenge', {
        url: '/level/{level}/challenge/{row}/{column}',
        params: { level: null, row: null, column: null }, // must be present for notification_id to show up
        templateUrl: 'level/challenge/challenge.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          getLevelData: [
            'Level',
            '$stateParams',
            function (Level, $stateParams) {
              // Ensure we have the level data, in case the user hits refresh
              return Level.update($stateParams.level);
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      })

      .state('app.keyboarding.lesson', {
        url: '/level/{level}/challenge/{row}/{column}/lesson/{lesson}',
        params: { level: null, row: null, column: null, lesson: null }, // must be present for notification_id to show up
        controller: 'LessonController',
        templateUrl: 'level/challenge/lesson/_lesson.html',
        resolve: {
          userLoggedIn: [
            'Auth2',
            '$state',
            '$rootScope',
            function (Auth2, $state, $rootScope) {
              return Auth2.currentUser().then(
                function (user) {
                  $rootScope.user = user;
                },
                function () {
                  $state.go('authentication.sign_in');
                }
              );
            },
          ],
          getLevelData: [
            'Level',
            '$stateParams',
            function (Level, $stateParams) {
              // Ensure we have the level data, in case the user hits refresh
              return Level.update($stateParams.level);
            },
          ],
          addHomeRowTechSettingsToCache: [
            'UserSettings',
            'CacheService',
            function (UserSettings, CacheService) {
              // Get homeRowTech settings from firebase, put them in the cache so we can access them in SkipHomeRowTechModal
              //    FYI UserSettings and CacheService get updated from HomeRowTechModalFactory
              return UserSettings.get('homeRowTech').then(function (setting) {
                CacheService.set(setting.orDefault({ lastCompletedAt: null }), 'homeRowTech'); // set homeRowTech to the cache
              });
            },
          ],
          accountSettings: [
            'UserSettings',
            'DefaultSettings',
            (UserSettings, DefaultSettings) => {
              return UserSettings.get('accountSettings').then((res) => res.orDefault(DefaultSettings.accountSettings()));
            },
          ],
        },
        onExit: function () {
          // remove the faded background if a modal was active.
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        },
      });
  },
]);
